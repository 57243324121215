import React from "react"
import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"
import { ButtonFloating } from "./ButtonFloating"
import * as styles from "./banner.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

import { TypeBannerProps } from "../helpers"

const defaultTitle = `
До 36 месяцев рассрочки и до 5 000 ₽ кэшбэка
`

const defaultSubtext = `
— Совершайте покупки в интернет-магазинах партнеров Халвы
— Крутите Колесо Фортуны на промо-странице и получайте бонусы от Халвы
`

export function Banner({
  buttonText = "Оформить карту",
  title = defaultTitle,
  description = defaultSubtext,
  orderNum,
}: TypeBannerProps) {
  return (
    <>
      <section className={styles.section} data-exclude={orderNum}>
        <Container className={styles.container}>
          <div className={styles.secondContainer}>
            <p>22.01&#160;- 29.01</p>

            <Img alt="bg image" className={styles.img} />
            <div className={styles.text}>
              <h1 dangerouslySetInnerHTML={{ __html: title }} />
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
              {buttonText}
            </Button>
          </div>
        </Container>
      </section>
      <ButtonFloating onClick={() => handleClickBanner("sticky")}>Оформить карту</ButtonFloating>
    </>
  )
}
